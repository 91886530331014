<template>
    <div id="data-list-list-view" class="data-list-container">
      <vs-table
        ref="table"
        :sst="true"
        :total="totalDocs"
        :data="categoryData"
        @search="handleSearch"
        @change-page="handleChangePage"
        @sort="handleSort"
        :max-items="dataTableParams.limit"
        search
        :noDataText="noDataText"
      >
        <div slot="header" class="flex flex-wrap-reverse flex-grow justify-between">
          <div style="float: left">
            <vs-select
              placeholder="10"
              vs-multiple
              autocomplete
              v-model="dataTableParams.limit"
              class="mr-6"
            >
              <vs-select-item
                :key="index"
                :value="item.value"
                :text="item.text"
                v-for="(item,index) in limitOptions"
                :clearable="false"
              />
            </vs-select>
          </div>
        </div>

        <template slot="thead">
          <vs-th>Image</vs-th>
          <vs-th sort-key="name">Name</vs-th>
          <vs-th>No of Products</vs-th>
          <vs-th>Sub Category</vs-th>
          <vs-th>Actions</vs-th>
        </template>

        <template slot-scope="{ data }">
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
            <vs-td :data="data[indextr].image">
              <div v-if="data[indextr].image || data[indextr].mobileImage">
                <div style="width: 100px; height: auto;">
                  <img
                    :src="data[indextr].image || data[indextr].mobileImage"
                    class="img-fluid"
                    alt=""
                  />
                </div>
              </div>
              <div v-else>
                <p>NA</p>
              </div>
            </vs-td>
            <vs-td :data="data[indextr].name">
              {{
              data[indextr].name
              }}
            </vs-td>
            <vs-td :data="data[indextr].productsDetails.length">
              {{
              data[indextr].productsDetails.length
              }}
            </vs-td>
            <vs-td :data="data[indextr].subCategoryDetails">
              <div v-if="data[indextr].subCategoryDetails.length > 0">
                <div v-for="(item) in data[indextr].subCategoryDetails" v-bind:key="item">
                  {{
                  item.name
                  }}
                </div>
              </div>
              <div v-else>
                <p>NA</p>
              </div>
            </vs-td>
            <vs-td :data="data[indextr]._id">
              <vx-tooltip
                position="left"
                text="Share Category"
              >
                <vs-button
                  @click="shareCategory(data[indextr])"
                  type="border"
                  size="small"
                  icon-pack="feather"
                  icon="icon-share"
                  class="mr-2"
                />
              </vx-tooltip>
              <vx-tooltip text="Update Category" position="left" >
                <vs-button
                  type="border"
                  size="small"
                  @click="editCategory(data[indextr]._id)"
                  icon-pack="feather"
                  icon="icon-edit"
                  class="mr-2 mt-1"
                ></vs-button>
              </vx-tooltip>
              <vx-tooltip text="Delete Category" position="left">
                <vs-button
                  type="border"
                  size="small"
                  color="danger"
                  @click="openDeletePopup(data[indextr]._id,data[indextr].productsDetails, data[indextr].subCategoryDetails)"
                  icon-pack="feather"
                  icon="icon-trash"
                  class="mt-1"
                ></vs-button>
              </vx-tooltip>
            </vs-td>
            <vs-popup :active.sync="popUpDelete" title="Delete Category" >
              <span v-if="popupData.productData.length > 0" align="center">
                <p>The category contains following product:</p>
                  <div v-for="(item) in popupData.productData" v-bind:key="item">
                    {{
                  item.name
                  }}
                  </div>
                  <p> If you delete the category, it will be removed from the associated product as well.</p>
              </span>
              <br />
              <span v-if="popupData.subCategoryData.length > 0" align="center">
                <p>The category contains following sub category:</p>
                  <div v-for="(item) in popupData.subCategoryData" v-bind:key="item">
                    {{
                  item.name
                  }}
                  </div>
                  <p> If you delete the category associated sub categories will be deleted as well.</p>
              </span>
              <br />
              <p align="center">Are you sure you want to delete this Category?</p>
              <br />
              <vs-row vs-align="flex-start" vs-type="flex" vs-justify="left" vs-w="12" vs-mt="6">
                <vs-col vs-type="flex" vs-align="left" vs-w="6">
                  <div class="w-full m-5" align="right">
                    <vs-button
                      size="lg"
                      color="primary"
                      @click="deleteDetailHandler(popupData.id)"
                    >Yes</vs-button>
                  </div>
                </vs-col>
                <vs-col vs-type="flex" vs-align="left" vs-w="6">
                  <div class="w-full m-5">
                    <vs-button size="lg" color="danger" @click="popUpDelete=false">Cancel</vs-button>
                  </div>
                </vs-col>
              </vs-row>
            </vs-popup>
          </vs-tr>
        </template>
      </vs-table>
      <span class="mr-2">
        {{
        dataTableParams.page * dataTableParams.limit -
        (dataTableParams.limit - 1)
        }}
        -
        {{
        totalDocs - dataTableParams.page * dataTableParams.limit > 0
        ? dataTableParams.page * dataTableParams.limit
        : totalDocs
        }}
        of {{ totalDocs }}
      </span>
      <div class="pagination-div" v-if="serverResponded">
        <paginate
          :page-count="totalPage"
          :click-handler="handleChangePage"
          class="pagination"
          :page-range="9"
          :prevText="'<'"
          :nextText="'>'"
        ></paginate>
      </div>
    </div>
</template>

<script>
  import vSelect from "vue-select";
  import { mapActions } from "vuex";

  export default {
    name: "CategoryList",
    components: {
      "v-select": vSelect
    },
    props: ["categoryData", "totalDocs", "page", "noDataText","popUpDeleteParent"],
    data() {
      return {
        popUpDelete: this.popUpDeleteParent,
        customerCSVurl: false,
        isMounted: false,
        currentPage: 1,
        popupData:{
          id:'',
          productData:[],
          subCategoryData: []
        },
        dataTableParams: {
          search: "",
          sort: "createdAt",
          dir: "desc",
          page: 1,
          limit: 10
        },
        limitOptions: [
          { text: "5", value: 5 },
          { text: "10", value: 10 },
          { text: "25", value: 25 },
          { text: "50", value: 50 },
          { text: "100", value: 100 }
        ],
        serverResponded: false,
        awaitingSearch: null
      };
    },
    methods: {
      shareCategory(categoryData){
        const domain = window.location.origin;
        const url = domain + "/c/" + categoryData._id + "?categorySlug=" + categoryData.slug;
        navigator.clipboard.writeText(url).then(() => {
          this.$vs.notify({
            title: 'Success',
            text: "Category URL copied successfully!",
            color: 'success',
          });
        }).catch(err => {
          console.log("Failed to copy text: ", err);
        });
      },
      async fetchList() {
        this.$vs.loading();
        await this.$emit("fetchList", this.dataTableParams);
        this.serverResponded = true;
        this.$vs.loading.close();
      },
      editCategory(id) {
        this.$emit("editCategory", id);
      },
      openDeletePopup(id,productData, subCategoryData){
        this.popupData.id = id;
        this.popupData.productData = productData;
        this.popupData.subCategoryData = subCategoryData;
        this.popUpDelete=true;
      },
      handleSearch(searching) {
        if (this.awaitingSearch) {
          clearTimeout(this.awaitingSearch);
          this.awaitingSearch = null;
        }
        this.awaitingSearch = setTimeout(() => {
          this.serverResponded = false;
          this.dataTableParams.search = searching;
          this.dataTableParams.page = 1;
          this.$refs.table.currentx = 1;
          this.fetchList();
        }, 500);
      },
      handleChangePage(page) {
        this.dataTableParams.page = page;
        this.fetchList();
      },
      handleSort(key, active) {
        this.serverResponded = false;
        this.dataTableParams.sort = key;
        this.dataTableParams.dir = active;
        this.dataTableParams.page = 1;
        this.$refs.table.currentx = 1;
        this.fetchList();
      },
      async deleteDetailHandler(id) {
        await this.$emit("deleteCategory", {id,dataTableParams:this.dataTableParams});
        this.popUpDelete=false;
      },
      exportCsvHandler() {
        let _instance = this;
        let csvLinkInstance = _instance.$refs.downloadCustomerCSVRef;

        //csvLinkInstance.click();
        this.exportCsv()
          .then(async res => {
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "freshclinic-patients.csv"); //or any other extension
            document.body.appendChild(link);
            link.click();
          })
          .catch(err => {
            console.log(err);
          });
      }
    },
    watch: {
      "dataTableParams.page": function(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.dataTableParams.page = newVal;
          this.fetchList();
        }
      },
      "dataTableParams.limit": function(newlimit, oldLimit) {
        if (newlimit !== oldLimit) {
          this.serverResponded = false;
          this.dataTableParams.page = 1;
          this.dataTableParams.limit = newlimit;
          this.fetchList();
        }
      }
    },
    computed: {
      totalPage: function() {
        return this.totalDocs / this.dataTableParams.limit >
        parseInt(this.totalDocs / this.dataTableParams.limit)
          ? parseInt(this.totalDocs / this.dataTableParams.limit) + 1
          : parseInt(this.totalDocs / this.dataTableParams.limit);
      }
    },
    created() {
      this.noDataText = "Loading...";
      this.fetchList();
    }
  };
</script>
